import React, { useState, useEffect } from 'react';
import { auth, googleProvider } from '../firebase';
import { signInWithRedirect, signInWithPopup, signInWithEmailAndPassword, updateProfile } from 'firebase/auth';



function LoginBox(props) {
  const { showPasswordBox, onLoginSuccess,  } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleForgotPasswordClick = (e) => {
    e.stopPropagation(); // Prevent event propagation
    showPasswordBox();
  };

  const handleSignIn = (e) => {
    e.stopPropagation(); // Prevent event propagation
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log('Signed in successfully:', user);
   

        if (onLoginSuccess) {
          onLoginSuccess();
        }
      })
      .catch((error) => {
        setError('Forgot your password? Click here');
      });
  };

  const handlePasswordInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignIn(event);
    }
  };



  /*
  const signInWithFacebook = async () => {
    try {
      // Delay before closing the box
      setTimeout(() => {
        if (onLoginSuccess) {
          onLoginSuccess();
        }
      }, 100); // Adjust the delay duration (500ms in this example)
  
      await signInWithRedirect(auth, facebookProvider);
    } catch (err) {
      console.error(err);
    }
  };
*/

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 767); // Adjust the breakpoint as needed
    };

    // Initial check
    checkIsMobile();

    // Add event listener for window resize to update isMobile
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  
  const signInWithGoogle = async () => {
    try {
      const isIPhoneOrAndroid = /iPhone|Android/i.test(navigator.userAgent);
      const isEdgeOrFirefox = /Edge|Firefox/i.test(navigator.userAgent);
  
      if (isIPhoneOrAndroid || isEdgeOrFirefox) {
        // For mobile devices, Edge, or Firefox: Use popup
        const signInPromise = signInWithRedirect(auth, googleProvider);
  
        // Set a timeout to close the login box after 3 seconds
        setTimeout(() => {
          if (onLoginSuccess) {
            onLoginSuccess();
          }
        }, 3000);
  
        // Wait for the sign-in to complete
        const userCredential = await signInPromise;
        const user = userCredential.user;
        const firstName = user.displayName.split(' ')[0];
        console.log('Signed in successfully with Google using popup:', firstName);
  
      } else {
        // For other desktop browsers: Use redirect
        // Set a timeout to close the login box after 3 seconds
        setTimeout(() => {
          if (onLoginSuccess) {
            onLoginSuccess();
          }
        }, 3000);
  
        // Initiate the redirect
        await signInWithRedirect(auth, googleProvider);
      }
    } catch (err) {
      console.error('Google sign-in error:', err);
      // Optionally handle the error (e.g., show an error message)
    }
  };

return (
  
<div className="login-box" id="loginBox">
      <div >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="black"
            strokeWidth={1.5}
            onClick={onLoginSuccess}
            stroke="currentColor"
            className="image-go-back-svg"
            viewBox="0 -960 960 960">
              <path d="M480-437.847 277.076-234.924q-8.307 8.308-20.884 8.5-12.576.193-21.268-8.5-8.693-8.692-8.693-21.076t8.693-21.076L437.847-480 234.924-682.924q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693L480-522.153l202.924-202.923q8.307-8.308 20.884-8.5 12.576-.193 21.268 8.5 8.693 8.692 8.693 21.076t-8.693 21.076L522.153-480l202.923 202.924q8.308 8.307 8.5 20.884.193 12.576-8.5 21.268-8.692 8.693-21.076 8.693t-21.076-8.693L480-437.847Z"/>
              </svg>
      </div>
    <div className='login-container'>
      <div className="login-content">

  <div className="login-image">

      <img src={`/images/Tios/Earning-Tio.webp`} alt="TIO illustration" className="img-fluid-tio" />

  </div>


            
        <div className='login-context'>
        <div className="log-in-text">
          <h4 className="h3 mb-3">Login or sign up</h4>
        </div>
    <h6 className="log-in-text-closing mb-4">
      <p className="fw-light">The most sensible way to use AI and get the results you need!</p>
    </h6>
    {isMobile && (
      <img
        src={`/images/logo/Logo-dark.webp`}
        alt="TioAssist logo"
        width="100"
        style={{
          position: 'absolute',
          top: 15,
          left: 10,
        }}
      />
    )}


    
  {/* Social media buttons section */}
  <div className="social-login">
  {/* Google sign-in button */}
  <button id="google-login" className="social-button google" onClick={signInWithGoogle} type="button">
  <span className="text" >Continue with Google</span>
  <span className="icon">
  {/* Google icon */}
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="35" width="35">
  <path fill="#4285f4" d="M386 400c45-42 65-112 53-179H260v74h102c-4 24-18 44-38 57z"></path>
  <path fill="#34a853" d="M90 341a192 192 0 0 0 296 59l-62-48c-53 35-141 22-171-60z"></path>
  <path fill="#fbbc02" d="M153 292c-8-25-8-48 0-73l-63-49c-23 46-30 111 0 171z"></path>
  <path fill="#ea4335" d="M153 219c22-69 116-109 179-50l55-54c-78-75-230-72-297 55z"></path>
  </svg>
  </span>
  </button>

{/*
  <button id="facebook-login" className="social-button facebook" onClick={signInWithFacebook}type="button">
  <span className="icon">
  <svg fill="none" height="35" viewBox="0 0 24 24" width="35" xmlns="http://www.w3.org/2000/svg">
  <path d="M21.5 12.058c0-5.28-4.253-9.558-9.5-9.558s-9.5 4.279-9.5 9.558c0 4.771 3.473 8.725 8.016 9.442v-6.68H8.104v-2.762h2.412V9.952c0-2.395 1.417-3.718 3.588-3.718 1.04 0 2.126.186 2.126.186v2.352h-1.198c-1.18 0-1.548.738-1.548 1.494v1.792h2.635l-.421 2.763h-2.214V21.5c4.543-.717 8.016-4.67 8.016-9.442z" fill="#3b5998"></path>
  </svg> 
  </span>
  <span className="text" >Continue with Facebook</span>
  </button>
*/}

  {/* email sign-in button */}
  <button id="email-login" className="social-button" onClick={props.showEmailBox} type="button">
    <span className="icon">
      <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 24 24" width="35">
        <path fill="currentColor"
            d="M20.37 5.03A2 2 0 0 1 22 7v10a2 2 0 0 1-1.96 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h16.1H4zm.13 2.07-4.53 5.31 4.53 4.63a.5.5 0 0 0 0-.04V7.1zm-17-.14a.5.5 0 0 0 0 .04v10a.5.5 0 0 0 0 .04l4.59-4.7L3.5 6.97zm5.57 6.53-3.92 4 13.7.01L15 13.56a4 4 0 0 1-5.93-.07zm9.88-6.99H5l5.07 5.96a2.5 2.5 0 0 0 3.81 0l5.07-5.96z">
        </path>
      </svg>
    </span>
    <span className="text">Continue with Email</span>
  </button>



  <div className="divider">
    <span className="divider-text">OR</span>
  </div>

  <div className="signupForm">


    <div className="col-lg-12 mb-3">
      <input
        type="email"
        placeholder="email"
        id="input-user-Email"
        name="email" // Add the name attribute
        className=" form-control form-control-lg borderradius"
        autoComplete="email"
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handlePasswordInputKeyDown}

      />
    </div>
    <div className="col-lg-12 mb-3">
      <input
        type="password"
        placeholder="password"
        id="input-user-Password"
        name="password" // Add the name attribute
        className=" form-control form-control-lg borderradius"
        autoComplete="new-password"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={handlePasswordInputKeyDown}

      />
    </div>
  </div>



    <div className="row custom-row">
      <div className="col">
        <button
          id="signInButton"
          className="signup-button"
          type="button"
          onClick={handleSignIn}
        >
          Login
        </button>
      </div>

      <div className="col">
        <button
          id="forgotPasswordButton"
          className="signup-button"
          type="button"
          onClick={props.showSignUpBox}
        >
          New User?
        </button>
      </div>
    </div>



  {/* "Continue sign up" button */} 




  <div className='singuperrormessage'>
    {error && (
      <p className="fw-light" style={{ color: 'red' }}>
        <a href="#" onClick={handleForgotPasswordClick} style={{ color: 'red', textDecoration: 'none' }}>
          {error}
        </a>
      </p>
    )}
  </div>

</div>
<div className="terms-container">
  <div role="separator" className="terms-divider">
<div className="terms">
<p className="fw-light" style={{ fontSize: '14px' }}>
  By continuing, you agree to our <a href="URL_TO_TERMS_OF_USE" target="_blank" rel="noopener">Terms of Use</a>.
  Read our <a href="URL_TO_PRIVACY_POLICY" target="_blank" rel="noopener">Privacy Policy</a>.
</p>
</div>
</div>
  </div>
  </div>
  
  </div>

  </div>
</div>

);
}

export default LoginBox;
